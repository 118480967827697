import React from "react";
import { Link, graphql } from "gatsby";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import PageHeader from "../components/page/header";
import PageDivider from "../components/page/divider";
import PageFooter from "../components/page/footer";

const WeddingPage = ({ data }) => {
  return (
    <Layout page="wedding">
      <SEO title="The Wedding" />

      <PageHeader title={data.file.childPagesYaml.title} />

      <Row className="page-content">
        <Col className="col-md-1" />
        <Col className="col-12 col-md-5">
          <img src={data.file.childPagesYaml.imageOne} />
        </Col>
        <Col className="col-12 col-md-4">
          <p>{data.file.childPagesYaml.intro}</p>
        </Col>
        <Col />
      </Row>

      <Row className="page-content">
        <Col className="col-0 col-md-2 d-none d-md-block"/>
        <Col className="col-12 col-sm-6 col-md-4">
          <p>{data.file.childPagesYaml.aboutUs}</p>
        </Col>
        <Col className="col-12 col-sm-6 col-md-5">
          <div>
            <img src={data.file.childPagesYaml.imageTwo} />
          </div>
        </Col>
      </Row>

      <PageDivider />

      <Row className="page-content">
        <Col className="col-12 col-sm-1" />
        <Col className="col-12 col-sm-4">
          <img src={data.file.childPagesYaml.imageThree} />
        </Col>
        <Col className="col-12 col-sm-4">
          <p>{data.file.childPagesYaml.eventTextOne}</p>
        </Col>
        <Col />
      </Row>

      {/* <Row className="page-content"> */}
      {/*   <Col className="col-sm-2" /> */}
      {/*   <Col className="col-12 col-sm-4"> */}
      {/*     <p>{data.file.childPagesYaml.eventTextTwo}</p> */}
      {/*   </Col> */}
      {/*   <Col className="col-12 col-sm-5"> */}
      {/*     <div> */}
      {/*       <img src={data.file.childPagesYaml.imageFour} /> */}
      {/*     </div> */}
      {/*   </Col> */}
      {/*   <Col /> */}
      {/* </Row> */}

      <PageDivider />

      <Row className="page-content">
        <Col className="col-sm-12" style={{ textAlign: "center" }}>
          <h3>Schedule of Festivities</h3>
        </Col>
      </Row>

      <Row className="page-content">
        <Col className="col-sm-1" />
        <Col className="col-12 col-sm-6">
          <h4>November 8</h4>
          <hr />
          {data.file.childPagesYaml.nov8.map((value, index) => {
            return (
              <p key={index}>
                <i>{value.time}</i> — <b>{value.event}</b>
                <br />
                {value.description}
              </p>
            );
          })}
        </Col>
      </Row>

      <Row className="page-content">
        <Col className="col-sm-4" />
        <Col className="col-12 col-sm-6">
          <h4>November 9</h4>
          <hr />
          {data.file.childPagesYaml.nov9.map((value, index) => {
            return (
              <p key={index}>
                <i>{value.time}</i> — <b>{value.event}</b>
                <br />
                {value.description}
              </p>
            );
          })}
        </Col>
      </Row>

      <PageFooter />
    </Layout>
  );
};

export const query = graphql`
  {
    file(relativePath: { eq: "pages/wedding.yml" }) {
      childPagesYaml {
        title
        headline
        intro
        aboutUs
        imageOne
        imageTwo
        eventHeadline
        eventTextOne
        eventTextTwo
        imageThree
        imageFour
        nov8 {
          time
          event
          description
        }
        nov9 {
          time
          event
          description
        }
      }
    }
  }
`;

export default WeddingPage;
